import * as React from "react";
import { useMemo } from "react";

import { DatoImage } from "@byko/component-dato-image";
import { TextBlockVariantOne } from "@byko/text-block-variant-one";

import {
  Container,
  PrimaryImageContainer,
  SecondaryImageAContainer,
  SecondaryImageBContainer,
  TextBlockContainer,
} from "./styles";

import type { TopBannerVariantOneProps } from "./interface";

export const TopBannerVariantOne = ({
  heading,
  paragraph,
  primaryImage,
  secondaryImageA,
  secondaryImageB,
  mirrorXAxis = false,
  mirrorYAxis = false,
  headingDecoration,
  introText,
  actionButton,
}: TopBannerVariantOneProps): JSX.Element => {
  const secondaryImageCount = useMemo((): number => {
    return Number(Boolean(secondaryImageA)) + Number(Boolean(secondaryImageB));
  }, [secondaryImageA, secondaryImageB]);

  return (
    <Container mirrorX={mirrorXAxis} mirrorY={mirrorYAxis} secondaryImageCount={secondaryImageCount}>
      <TextBlockContainer>
        <TextBlockVariantOne
          buttonColor="whiteButton"
          buttonLabel={actionButton?.text}
          buttonLink={actionButton?.link}
          darkMode={true}
          heading={heading}
          headingDecoration={headingDecoration}
          introText={introText}
          maxColumns={1}
          paragraph={paragraph}
        />
      </TextBlockContainer>
      <PrimaryImageContainer>
        <DatoImage {...primaryImage} priority={true} />
      </PrimaryImageContainer>
      {secondaryImageA ? (
        <SecondaryImageAContainer>
          <DatoImage {...secondaryImageA} />{" "}
        </SecondaryImageAContainer>
      ) : null}
      {secondaryImageB ? (
        <SecondaryImageBContainer>
          <DatoImage {...secondaryImageB} />{" "}
        </SecondaryImageBContainer>
      ) : null}
    </Container>
  );
};
