import { useParagraphValue } from "./use-paragraph";

import type { ParagraphValue } from "./use-paragraph";
import type { BulletContainer } from "@byko/lib-api-dato-client";

type BulletProps = BulletContainer;

interface BulletValue extends ParagraphValue {
  itemSection: {
    title: string;
    items: { id: number; content: string }[];
  };
}

export const useBulletValue = ({ title, intro, content }: BulletProps): BulletValue => {
  const paragraph = useParagraphValue(intro);
  const itemSection = {
    ...paragraph,
    itemSection: {
      title: title,
      items: content,
    },
  };
  return itemSection;
};
