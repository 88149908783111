import React from "react";

import { ContentSectionPhotoAlbum } from "@byko/component-content-section-photo-album";

import { Divider } from "../divider";

import type { PhotoGalleryBlockSection as IPhotoGalleryBlockSection } from "@byko/lib-api-dato-client";

export const ContentSectionPhotoGalleryBlock = (content: IPhotoGalleryBlockSection): JSX.Element => {
  if (content.hidden) return <></>;
  return (
    <>
      <Divider />
      <ContentSectionPhotoAlbum photos={content.images} />
    </>
  );
};
export type PhotoGalleryBlockSection = IPhotoGalleryBlockSection;
