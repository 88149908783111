import React from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantSix } from "@byko/content-section-variant-six";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection6 as IContentSection6 } from "@byko/lib-api-dato-client";
import { Container } from "../styles";

export const ContentSection6 = (content: IContentSection6): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <Container>
        <ContentSectionVariantSix mirrorX={content.mirrorX} primaryImage={content.image} {...paragraphProps} />
      </Container>
    </PageContainer>
  );
};

export type ContentSection6 = IContentSection6;
