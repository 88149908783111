import React from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantOne } from "@byko/content-section-variant-one";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection1 as IContentSection1 } from "@byko/lib-api-dato-client";

export const ContentSection1 = (content: IContentSection1): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);

  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <ContentSectionVariantOne mirrorY={content.mirrorY} primaryImage={content.image} {...paragraphProps} />
    </PageContainer>
  );
};
export type ContentSection1 = IContentSection1;
