import React from "react";

import { Content } from "./content";
import { BykoPageContext } from "./context";
import { Divider } from "./divider";
import { Header } from "./header";
import { Top } from "./top";

import type { BykoPage as _BykoPage } from "@byko/lib-api-dato-client";

interface Props {
  page: _BykoPage;
}

export const BykoPage = ({ page }: Props): JSX.Element => {
  return (
    <BykoPageContext.Provider value={page}>
      <Header />
      <Top />
      <Content />
      <Divider />
    </BykoPageContext.Provider>
  );
};
