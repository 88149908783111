import React from "react";
import { useMemo } from "react";
import { StructuredText } from "react-datocms";

import { getIcon } from "../icon";

import type { ActionButton, HeadingUnderlineColor, Text } from "@byko/lib-api-dato-client";
import type { BykoReactDecorativeIconComponentType } from "@byko/lib-core";
import type { Maybe } from "@byko/types-utils";

export type ParagraphProps = Text;

export interface ParagraphValue {
  heading: string;
  paragraph: JSX.Element;
  headingDecoration: BykoReactDecorativeIconComponentType | null;
  headingUnderlineColor: HeadingUnderlineColor;
  introText: string | null;
  actionButton: Maybe<ActionButton>;
}

export const useParagraphValue = ({
  title: heading,
  textBlockContent,
  headingUnderlineColor,
  icon,
  introText,
  actionButton,
}: ParagraphProps): ParagraphValue => {
  const paragraph = useMemo(() => {
    return <StructuredText data={textBlockContent} />;
  }, [textBlockContent]);
  const headingDecoration = useMemo(() => {
    return icon ? getIcon({ icon: icon }) : null;
  }, [icon]);
  return {
    actionButton,
    heading,
    paragraph,
    headingDecoration,
    headingUnderlineColor,
    introText: introText ?? null,
  };
};
