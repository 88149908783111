import React, { useMemo } from "react";

import { ContentSectionEight } from "@byko/component-content-section-variant-eight";
import { PageContainer } from "@byko/component-page-container";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection8 as IContentSection8 } from "@byko/lib-api-dato-client";

export const ContentSection8 = (content: IContentSection8): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);

  const smallImageOne = useMemo(() => {
    if (content.images && content.images.length >= 1) {
      return content.images[0];
    }
    return undefined;
  }, [content.images]);

  const smallImageTwo = useMemo(() => {
    if (content.images && content.images.length >= 2) {
      return content.images[1];
    }
    return undefined;
  }, [content.images]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <ContentSectionEight
        decorativeImageOne={content.primaryImage}
        mirrorX={content.mirrorY}
        smallImageOne={smallImageOne}
        smallImageTwo={smallImageTwo}
        {...paragraphProps}
      />
    </PageContainer>
  );
};

export type ContentSection8 = IContentSection8;
