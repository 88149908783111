import React, { useMemo } from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantSeven } from "@byko/text-block-variant-seven";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection7 as IContentSection7 } from "@byko/lib-api-dato-client";

type CardProps<T extends string> = Record<`${T}Label`, string> & Record<`${T}Image`, string>;

const getCard =
  (value: IContentSection7["images"]) =>
  <T extends string>(index: number, name: T): Record<never, never> | CardProps<T> => {
    if (!value) {
      return {};
    }
    const content = value[index];
    if (!content) {
      return {};
    }
    return {
      [`${name}Label`]: content.title,
      [`${name}Image`]: content.jpeg,
      [`${name}Link`]: content.link,
    };
  };

export const ContentSection7 = (content: IContentSection7): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);
  const cardProps = useMemo(() => {
    const fn = getCard(content.images);
    return {
      ...fn(0, "cardOne"),
      ...fn(1, "cardTwo"),
      ...fn(2, "cardThree"),
      ...fn(3, "cardFour"),
    };
  }, [content.images]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <ContentSectionVariantSeven
        mirrorY={content.mirrorY}
        primaryImage={content.primaryImage}
        {...cardProps}
        {...paragraphProps}
      />
    </PageContainer>
  );
};

export type ContentSection7 = IContentSection7;
