import React, { useMemo } from "react";

import * as icons from "@byko/lib-icons";

import type { BykoIcon } from "@byko/lib-api-dato-client";
import type { BykoReactDecorativeIconComponentType } from "@byko/lib-icons";
interface Props {
  icon: BykoIcon;
}

export const getIcon = ({ icon }: Props): BykoReactDecorativeIconComponentType => {
  return icons[icon];
};

export const Icon = ({ icon }: Props): JSX.Element | null => {
  const Component = useMemo(() => {
    return icons[icon];
  }, [icon]);
  if (!Component) {
    return null;
  }
  return <Component />;
};
