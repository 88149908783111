import styled from "styled-components";

import { gridTheme, theme } from "@byko/lib-styles";

import type { ContainerProps } from "./interface";

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: grid;
  width: 100%;
  max-width: ${gridTheme.maxContentWidth}px;
  border: 8px solid ${theme.palette.white.main};
  margin-top: 16px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${theme.palette.white.main};
  ${(props): string =>
    props.mirrorX && props.mirrorY && props.secondaryImageCount === 2
      ? `grid-template-areas:
      "primary secondarya secondaryb "
      "primary text text";`
      : ""}

  ${(props): string =>
    !props.mirrorX && props.mirrorY && props.secondaryImageCount === 2
      ? `grid-template-areas:
      "primary text text"
      "primary secondarya secondaryb";`
      : ""}
  
  ${(props): string =>
    props.mirrorX && !props.mirrorY && props.secondaryImageCount === 2
      ? `grid-template-areas:
      "secondarya secondaryb primary"
      "text text primary";`
      : ""}
  
  ${(props): string =>
    !props.mirrorX && !props.mirrorY && props.secondaryImageCount === 2
      ? `grid-template-areas:
      "text text primary"
      "secondarya secondaryb primary";`
      : ""}

  ${(props): string =>
    props.mirrorX && props.mirrorY && props.secondaryImageCount === 1
      ? `grid-template-areas:
      "primary secondarya secondarya "
      "primary text text";`
      : ""}

  ${(props): string =>
    !props.mirrorX && props.mirrorY && props.secondaryImageCount === 1
      ? `grid-template-areas:
      "primary text text"
      "primary secondarya secondarya";`
      : ""}
  
  ${(props): string =>
    props.mirrorX && !props.mirrorY && props.secondaryImageCount === 1
      ? `grid-template-areas:
      "secondarya secondarya primary"
      "text text primary";`
      : ""}
  
  ${(props): string =>
    !props.mirrorX && !props.mirrorY && props.secondaryImageCount === 1
      ? `grid-template-areas:
      "text text primary"
      "secondarya secondarya primary";`
      : ""}

  ${(props): string =>
    props.mirrorY && props.secondaryImageCount === 0
      ? `grid-template-areas: 
      "primary text text"
      "primary text text";`
      : ""}
  ${(props): string =>
    !props.mirrorY && props.secondaryImageCount === 0
      ? `grid-template-areas: 
      "text text primary"
      "text text primary";`
      : ""}

  grid-template-columns: 0.75fr 0.75fr 1fr;
  grid-template-rows: auto 333px;

  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    ${(props): string =>
      props.secondaryImageCount === 0
        ? `grid-template-areas: 
          "text text"
          "primary primary";`
        : ""}

    ${(props): string =>
      props.secondaryImageCount === 1
        ? `grid-template-areas: 
          "text text"
          "primary primary"
          "secondarya secondarya";`
        : ""}
    
    ${(props): string =>
      props.secondaryImageCount === 2
        ? `grid-template-areas: 
          "text text"
          "primary primary"
          "secondarya secondaryb"
          "secondarya secondaryb";`
        : ""}
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextBlockContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 96px 112px;
  border: 8px solid ${theme.palette.white.main};
  background-color: ${theme.palette.blue.dark};
  grid-area: text;
  transition: padding 300ms;

  @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
      .s}px) {
    padding: 48px 24px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.sm}px) and (max-width: ${gridTheme.container.maxWidth
      .sm}px) {
    padding: 64px 48px;
  }

  @media screen and (min-width: ${gridTheme.container.minWidth.md}px) and (max-width: ${gridTheme.container.maxWidth
      .md}px) {
    padding: 80px 56px;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 32px;
    background-color: ${theme.palette.yellow.main};
    content: "";
    transition: width 300ms;
    @media screen and (min-width: ${gridTheme.container.minWidth.xs}px) and (max-width: ${gridTheme.container.maxWidth
        .s}px) {
      width: 16px;
    }
  }

  p {
    max-width: 544px;
  }
`;

export const PrimaryImageContainer = styled.div`
  position: relative;
  height: 100%;
  border: 8px solid ${theme.palette.white.main};
  grid-area: primary;
  img {
    object-fit: cover !important;
  }
`;

export const SecondaryImageAContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 317px;
  border: 8px solid ${theme.palette.white.main};
  grid-area: secondarya;
  object-fit: cover;
  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    width: 100%;
    height: 200px;
  }
  img {
    object-fit: cover !important;
  }
`;

export const SecondaryImageBContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 317px;
  border: 8px solid ${theme.palette.white.main};
  grid-area: secondaryb;
  object-fit: cover;
  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    width: 100%;
    height: 200px;
  }
  img {
    object-fit: cover !important;
  }
`;
