import React, { useMemo } from "react";
import { StructuredText } from "react-datocms";

import { Breadcrumbs } from "@byko/component-breadcrumbs";
import { MotionWrapper } from "@byko/lib-styles";

import { useBykoPageContext } from "./context";
import { getIcon } from "./icon";

import type { Breadcrumb } from "@byko/component-breadcrumbs";
import type { DatoImageWithTitle } from "@byko/lib-api-dato-client";
import { TopBannerVariantOne } from "./content/top-banner-variant-one";

export const Top = (): JSX.Element => {
  const {
    breadcrumbs,
    top: { images, content, mirrorXAxis, mirrorYAxis },
  } = useBykoPageContext();
  if (!images[0]) {
    throw new Error("Invalid image");
  }
  const top__topPrimaryImage = images[0] as DatoImageWithTitle;
  const top__secondaryImageA = images[1] ?? null;
  const top__secondaryImageB = images[2] ?? null;
  const top__headingDecoration = content.icon ? getIcon({ icon: content.icon }) : null;
  const top__introText = content.introText;
  const top__paragraph = useMemo(() => {
    return <StructuredText data={content.textBlockContent} />;
  }, [content]);

  // blocking links for all breadcrumbs except first and last
  const filteredBreadcrumbs = useMemo((): Breadcrumb[] => {
    return breadcrumbs.map((breadcrumb, index) => {
      return {
        ...breadcrumb,
        disabled: breadcrumb.canBeNavigatedToInMegamenu == false || index == breadcrumbs.length - 1,
      };
    });
  }, [breadcrumbs]);

  return (
    <>
      <Breadcrumbs breadcrumbs={filteredBreadcrumbs} />
      <MotionWrapper>
        <TopBannerVariantOne
          actionButton={content.actionButton}
          heading={content.title}
          headingDecoration={top__headingDecoration}
          introText={top__introText}
          mirrorXAxis={mirrorXAxis}
          mirrorYAxis={mirrorYAxis}
          paragraph={top__paragraph}
          primaryImage={top__topPrimaryImage}
          secondaryImageA={top__secondaryImageA}
          secondaryImageB={top__secondaryImageB}
        />
      </MotionWrapper>
    </>
  );
};
