import styled from "styled-components";

import { gridTheme } from "@byko/lib-styles";

export const HeaderPad = styled.div`
  width: 100%;
  height: 88px;
  @media screen and (max-width: ${gridTheme.breakpoints.sm}px) {
    height: 56px;
  }
`;

export const ContentDivider = styled.div<{ small: boolean }>`
  width: 100%;
  height: ${(props): string => (props.small ? "88px" : "120px")};
  @media screen and (max-width: 1079px) {
    height: ${(props): string => (props.small ? "56px" : "80px")};
  }
`;

export const Container = styled.div`
  position: relative;
`;
