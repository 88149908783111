import styled from "styled-components";

import { PageContainer } from "@byko/component-page-container";
import { PMedium } from "@byko/component-typography";
import { gridTheme, theme } from "@byko/lib-styles";

import type { BreadlinkProps } from "./interface";

export const ScrollableContainer = styled(PageContainer)`
  display: flex;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`;

export const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  max-height: 57px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px 0;
  background-color: ${theme.palette.white.main};
  transition: all 0.3s;
  @media screen and (max-width: 895px) {
    display: none;
  }
`;

export const BreadWrapper = styled.div`
  display: flex;
  align-items: center;
  p {
    height: 100%;
    margin-bottom: 5px;
    white-space: nowrap;
  }
`;

export const BreadContainer = styled.div`
  margin-top: 1px;
`;

export const BreadLink = styled(PMedium)<BreadlinkProps>`
  color: ${theme.palette.blue.dark};
`;

export const Seperator = styled.div`
  margin: 2px 10px;
  svg {
    path {
      stroke-width: 3px;
    }
  }
  @media screen and (max-width: ${gridTheme.container.maxWidth.s}px) {
    svg {
      margin-bottom: 5px;
    }
  }
`;
