import { createContext, useContext } from "react";

import type { BykoPage } from "@byko/lib-api-dato-client";

export const BykoPageContext = createContext<BykoPage | null>(null);

export const useBykoPageContext = (): BykoPage => {
  const value = useContext(BykoPageContext);
  if (value == null) {
    throw new Error("Incorrect usage of BykoPageContext");
  }
  return value;
};
