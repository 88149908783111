import { useMemo } from "react";
import React from "react";
import { StructuredText } from "react-datocms";

import { getIcon } from "../icon";

import type { TextRow } from "@byko/content-section-variant-five/src/interface";
import type { List, ListItem } from "@byko/lib-api-dato-client";

type BulletProps = List;

type BulletValue = TextRow[];

const getListItem = (value: ListItem): TextRow => {
  const icon = value.icon ? getIcon({ icon: value.icon }) : null;
  const paragraph = <StructuredText data={value.text} />;
  return {
    id: value.id,
    title: value.title,
    paragraph,
    icon,
    number: !icon ? value.id : null,
  };
};

export const useList = (value: BulletProps): BulletValue => {
  return useMemo(() => {
    return value.map((item) => getListItem(item));
  }, [value]);
};
