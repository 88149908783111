import NextLink from "next/link";
import React from "react";

import { ArrowRightIcons } from "@byko/lib-icons";
import { theme } from "@byko/lib-styles";

import { BreadContainer, BreadLink, BreadWrapper, ScrollableContainer, Seperator, Wrapper } from "./styles";

import type { BreadcrumbsProps } from "./interface";

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps): JSX.Element => {
  return (
    <Wrapper>
      <ScrollableContainer flexDirection="row" justifyContent="flex-start" offsetNav={false}>
        {breadcrumbs?.map((bread, index) => {
          const key = index;
          const breadTitle = bread.title;
          const breadHref = bread.url;
          const selected = breadcrumbs.length - 1;

          return (
            <React.Fragment key={key}>
              {breadTitle && (
                <BreadWrapper>
                  {Boolean(key) && (
                    <Seperator>
                      <ArrowRightIcons iconColor={theme.palette.blue.dark} size={12} />
                    </Seperator>
                  )}
                  {bread.disabled === true || bread.url == undefined ? (
                    <BreadContainer>
                      <BreadLink selected={selected === key}>{breadTitle}</BreadLink>
                    </BreadContainer>
                  ) : (
                    <NextLink href={breadHref ?? "#"}>
                      <BreadLink selected={selected === key}>{breadTitle}</BreadLink>
                    </NextLink>
                  )}
                </BreadWrapper>
              )}
            </React.Fragment>
          );
        })}
      </ScrollableContainer>
    </Wrapper>
  );
};
