/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import React, { useMemo } from "react";
import { StructuredText } from "react-datocms";

import { ProductRentCard, ProductStandardCard } from "@byko/component-product-card";
import { ProductSliderSection } from "@byko/component-product-slider-section";
import type { ProductListAugmented } from "@byko/lib-api-products";
import { algoliaHitToProduct, useProductLowestPrices } from "@byko/lib-api-products";

import { Divider } from "../divider";

import type { ContentSectionProduct as IContentSectionProduct } from "@byko/lib-api-dato-client";
import { useAlgoliaProductQuery } from "@byko/lib-algolia";
import { ensureList } from "@byko/lib-utils";
import type { ProductList } from "@byko/lib-api-rest";
import { useAuth } from "@byko/hooks-auth-next";

export const ProductSection = (content: IContentSectionProduct): JSX.Element => {
  const { activeMembership } = useAuth();

  const idList = useMemo(() => {
    const list: string[] = [];
    content.products.forEach((product) => {
      const ids = product.productId.split("-");
      const actualId = ids[ids.length - 1];
      if (actualId) {
        list.push(actualId);
      }
    });
    return list;
  }, [content.products]);

  const paragraph = useMemo(() => {
    return <StructuredText data={content.paragraph} />;
  }, [content.paragraph]);

  const { data } = useAlgoliaProductQuery({ productIds: idList });

  const pricedProducts = data?.hits
    ?.filter(
      (p) =>
        !(
          p.salesAvailability === "custom" ||
          p.salesAvailability === "display" ||
          isRentalProduct(p) ||
          (!activeMembership?.account.hasContract && p.salesAvailability === "by_contract")
        ),
    )
    .map((p) => p.id);

  const { prices } = useProductLowestPrices(pricedProducts);
  const prods = ensureList(data?.hits.map(algoliaHitToProduct));

  const collection = useMemo((): JSX.Element[] => {
    const productCards = prods.map((product, index) => {
      if (isRentalProduct(product)) {
        return (
          <ProductRentCard
            key={product.id}
            addedLabel="Leiguvara"
            algoliaInsights={{
              eventName: "Rent Card Clicked",
              index: data?.index || "",
              queryID: data?.queryID || "",
              objectIDs: [product?.variants?.[0]?.sku || ""],
              positions: [index + 1],
            }}
            listIndex={index}
            product={product}
            smallMobileCard={true}
          />
        );
      } else {
        return (
          <ProductStandardCard
            key={product.id}
            algoliaInsights={{
              eventName: "Click ProductCard",
              index: data?.index || "",
              queryID: data?.queryID || "",
              objectIDs: [product?.id.toString() || ""],
              positions: [index + 1],
            }}
            analyticsSectionId="front-page-product-slider"
            listIndex={index}
            lowestPriceSKU={prices?.[product.id] !== undefined ? Object.keys(prices?.[product.id] ?? {})[0] : undefined}
            prices={prices?.[product.id]}
            product={product}
            smallMobileCard={true}
          />
        );
      }
    });
    return productCards;
  }, [prices, prods, data]);
  if (content.hidden) return <></>;
  return (
    <>
      <Divider />
      <ProductSliderSection collection={collection} heading={content.heading} paragraph={paragraph} />
    </>
  );
};

export type ContentSectionProduct = IContentSectionProduct;

function isRentalProduct(product: ProductListAugmented | ProductList) {
  let isRental = false;
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isRental = product.attributes.typeParent.includes("Leigumarkaður");
  } catch {
    // silent
  }

  return isRental;
}
