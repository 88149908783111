import React, { useMemo } from "react";

import { ContentSectionNine } from "@byko/component-content-section-variant-nine";
import { PageContainer } from "@byko/component-page-container";
import { isNotNull } from "@byko/types-utils";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { CardsValue } from "@byko/component-content-section-variant-nine/src/interface";
import type { ContentSection9 as IContentSection9 } from "@byko/lib-api-dato-client";

const getCard =
  (value: IContentSection9["images"]) =>
  (index: number): null | CardsValue => {
    if (!value) {
      return null;
    }
    const content = value[index];
    if (!content) {
      return null;
    }
    return {
      label: content.title,
      image: content.jpeg,
      link: content.link,
    };
  };

export const ContentSection9 = (content: IContentSection9): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);

  const cardProps = useMemo(() => {
    if (!content.images) {
      return [] as CardsValue[];
    }
    const fn = getCard(content.images);
    return content.images
      .map((_e, i) => {
        return fn(i);
      })
      .filter(isNotNull);
  }, [content.images]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <ContentSectionNine cards={cardProps} mirrorX={content.mirrorY} {...paragraphProps} />
    </PageContainer>
  );
};

export type ContentSection9 = IContentSection9;
