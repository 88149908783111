import React, { useMemo } from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantTwo } from "@byko/content-section-variant-two";

import { Divider } from "../divider";

import { useParagraphValue } from "./use-paragraph";

import type { ContentSection2 as IContentSection2 } from "@byko/lib-api-dato-client";

export const ContentSection2 = (content: IContentSection2): JSX.Element => {
  const paragraphProps = useParagraphValue(content.textBlockContent);
  const { decorativeImageOne, decorativeImageTwo, decorativeImageThree } = useMemo(() => {
    if (!content.images) {
      return {
        decorativeImageOne: null,
        decorativeImageTwo: null,
        decorativeImageThree: null,
      };
    }
    return {
      decorativeImageOne: content.images[0] ?? null,
      decorativeImageTwo: content.images[1] ?? null,
      decorativeImageThree: content.images[2] ?? null,
    };
  }, [content.images]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <ContentSectionVariantTwo
        decorativeImageOne={decorativeImageOne}
        decorativeImageThree={decorativeImageThree}
        decorativeImageTwo={decorativeImageTwo}
        primaryImage={content.primaryImage}
        {...paragraphProps}
      />
    </PageContainer>
  );
};

export type ContentSection2 = IContentSection2;
