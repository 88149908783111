import React, { useMemo } from "react";

import { PageContainer } from "@byko/component-page-container";
import { ContentSectionVariantFive } from "@byko/content-section-variant-five";

import { Divider } from "../divider";

import { useList } from "./use-list";
import { useParagraphValue } from "./use-paragraph";

import type { DatoImageWithTitle, ContentSection5 as IContentSection5 } from "@byko/lib-api-dato-client";

export const ContentSection5 = (content: IContentSection5): JSX.Element => {
  const paragraph = useParagraphValue(content.textBlockContent);
  const textRows = useList(content.listBlockContent);
  const { decorativeImageOne } = useMemo(() => {
    return {
      decorativeImageOne: content.primaryImage as DatoImageWithTitle,
    };
  }, [content.primaryImage]);
  if (content.hidden) return <></>;
  return (
    <PageContainer flexDirection="column" offsetNav={false}>
      <Divider />
      <ContentSectionVariantFive
        decorativeImageOne={decorativeImageOne}
        mirrorY={content.mirrorY}
        textRows={textRows}
        {...paragraph}
      />
      <Divider />
    </PageContainer>
  );
};

export type ContentSection5 = IContentSection5;
